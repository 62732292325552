import axios from "axios";

const authorization = {
  state: {
    status: "",
    errorText: "",
    userData: JSON.parse(localStorage.getItem("userData")),
    userPerm: JSON.parse(localStorage.getItem("permissions")),
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      state.status = "success";
      state.userData = data;
    },
    auth_set_perm(state, data){
      state.userPerm = data;
    },
    auth_error(state, errorText) {
      state.status = "error";
      state.errorText = errorText;
      state.userData = undefined;
    },
    logout(state) {
      state.status = "logout";
      state.userData = undefined;
      state.userPerm = [];
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("email", user.email);
        formData.append("password", user.password);

        commit("auth_request");
        axios
          .post(`/v1/auth/login`, formData)
          .then(resp => {
            const token = resp.data.token;
            const refresh_token = resp.data.refresh_token;
            const email = resp.data.email;
            const perms = resp.data.permissions;
            const roles = resp.data.roles;
            let userData = [{}];
            userData[0].token = token;
            userData[0].refresh_token = refresh_token;
            userData[0].email = email;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("permissions", JSON.stringify(perms));
            localStorage.setItem("roles", JSON.stringify(roles));
            //localStorage.setItem("updatePage", '1');
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            commit("auth_success", userData);
            commit("auth_set_perm", perms);
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error", err.response.data.message);
            localStorage.removeItem("userData");
            reject(err.response.data);
          });
      });
    },
    // register({ commit }, user) {
    //   return new Promise((resolve, reject) => {
    //     let formData = new FormData();
    //     formData.append("email", user.email);
    //     formData.append("username", user.name);
    //     formData.append("password", user.password);
    //     formData.append("phone_number", user.phone_number);
    //     formData.append("iAgree", user.iAgree);
    //     formData.append("user_contract", JSON.stringify(user.userContract));
    //
    //     axios
    //       .post(`/v1/auth/register`, formData)
    //       .then(resp => {
    //         resolve(resp);
    //       })
    //       .catch(err => {
    //         commit("auth_error", err.response.data.message);
    //         reject(err.response.data);
    //       });
    //   });
    // },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("userData");
        localStorage.removeItem("permissions");
        localStorage.removeItem("roles");
        delete axios.defaults.headers.common["Authorization"];
        if (localStorage.getItem("userData")) {
          reject();
        } else {
          resolve();
        }
      });
    }
  }
};

export default authorization;
