import Vue from 'vue'
import App from './App.vue'
import axios from "axios"
import { BootstrapVue, TooltipPlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import config from '@/config';
//import * as VueGoogleMaps from 'vue2-google-maps'
//import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";

//========= AXIOS
Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = config.rest_point +'/' +i18n.locale;

// Vue.prototype.$http.interceptors.request.use(function (config) {
//   // Подставим в запрос локаль
//   config.url = '/' +i18n.locale +config.url;
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

Vue.prototype.$http.interceptors.response.use(undefined, error => {
  let res = error.response;

  if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
    return new Promise((resolve, reject) => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let formData = new FormData();
      formData.append("refresh_token", userData[0].refresh_token);
      formData.append("token", userData[0].token);

      axios
          .post(`/v1/auth/refresh`, formData)
          .then(resp => {
            const tokenT = resp.data.token;
            const refresh_token = resp.data.refresh_token;
            const perms = resp.data.permissions;
            const roles = resp.data.roles;
            let userData = [{}];
            let getThisData = JSON.parse(localStorage.getItem("userData"));
            userData[0].token = tokenT;
            userData[0].refresh_token = refresh_token;
            userData[0].email = getThisData[0].email;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("permissions", JSON.stringify(perms));
            localStorage.setItem("roles", JSON.stringify(roles));
            Vue.prototype.$http.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${tokenT}`;
            error.config.headers.Authorization = `Bearer ${tokenT}`;
            error.config.__isRetryRequest = true;
            // axios(error.config);
            resolve(axios(error.config));
          })
          .catch(err => {
            store.dispatch("logout").then(() => {
              router.push("/login");
            });
            // commit("auth_error", err.response.message);
            reject(err);
          });
    });
  }
  return Promise.reject(error);
});

const userDataStorage = JSON.parse(localStorage.getItem("userData"));
let token = false;
if (userDataStorage) {
  token = userDataStorage[0].token;
}else{
    store.dispatch("logout").then(() => {
        router.push("/login");
    });
}

if (token) {
  Vue.prototype.$http.defaults.headers.common[
      "Authorization"
      ] = `Bearer ${token}`;
}
//========= END AXIOS

//import { initFirebaseBackend } from './helpers/firebase/authUtils';

//import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else {
//   configureFakeBackend();
// }

Vue.config.productionTip = false
//Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(TooltipPlugin)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
